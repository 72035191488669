import React from "react";
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, PinterestIcon, PinterestShareButton, TwitterIcon, TwitterShareButton } from "react-share";

const Sharing = () => {
	const shareUrl = typeof window !== 'undefined' ? window.location.href : '';
	return (
		<div className="social-share-links">
			<span className="mr-3 text-muted">Share: </span>
			<LinkedinShareButton url={shareUrl} className="mr-2">
				<LinkedinIcon size={32} round />
			</LinkedinShareButton>
			<TwitterShareButton url={shareUrl} className="mr-2">
				<TwitterIcon size={32} round />
			</TwitterShareButton>
			<FacebookShareButton url={shareUrl} className="mr-2">
				<FacebookIcon size={32} round />
			</FacebookShareButton>
		</div>
	);
};

export default Sharing;
